export const analyticsLabels = {
    BotSignature: 'User-Agent contains bot signature',
    Headless: 'Detected Headless Browser',
    NavProps: 'Suspicious navigator properties',
    PerfAPI: 'Unusual Performance API behavior',
    NoInteraction: 'No real user interaction detected',
};

const detectBot = () => {
    const failedChecks = [];

    if (window.__PUPPETEER_PREBUILD_MODE__) {
        return { isBot: false, failedChecks: [] };
    }

    const isBotByUserAgent = () => {
        const botPatterns = [
            /bot/i,
            /crawl/i,
            /spider/i,
            /slurp/i,
            /mediapartners/i,
            /facebook/i,
            /baiduspider/i,
            /yandex/i,
            /duckduckbot/i,
            /bingbot/i,
            /googlebot/i,
        ];
        const isBot = botPatterns.some((pattern) =>
            pattern.test(navigator.userAgent)
        );
        if (isBot) failedChecks.push(analyticsLabels.BotSignature);
        return isBot;
    };

    const isHeadlessBrowser = () => {
        const w = window;
        const isHeadless =
            w.navigator.webdriver ||
            /HeadlessChrome/.test(w.navigator.userAgent) ||
            !w.outerWidth ||
            !w.outerHeight;
        if (isHeadless) failedChecks.push(analyticsLabels.Headless);
        return isHeadless;
    };

    const isNavigatorSuspicious = () => {
        const missingLanguages =
            !navigator.languages || navigator.languages.length === 0;
        const missingHardwareConcurrency =
            !navigator.hardwareConcurrency || navigator.hardwareConcurrency < 1;
        const isWebDriver = navigator.webdriver === true;
        const isSuspicious =
            missingLanguages || missingHardwareConcurrency || isWebDriver;
        if (isSuspicious) failedChecks.push(analyticsLabels.NavProps);
        return isSuspicious;
    };

    const isPerformanceSuspicious = () => {
        const isSuspicious =
            performance.getEntriesByType('navigation').length === 0;
        if (isSuspicious) failedChecks.push(analyticsLabels.PerfAPI);
        return isSuspicious;
    };

    const checks = [
        isBotByUserAgent(),
        isHeadlessBrowser(),
        isNavigatorSuspicious(),
        isPerformanceSuspicious(),
    ];
    const isBot = checks.some(Boolean);

    return { isBot, failedChecks };
};

export default detectBot;
